import React, { Component } from 'react'
import Homeimage from '../components/Home/Homeimage'


export default class Homepage extends Component {
    render() {
        return (
            <div>
                <Homeimage />
            </div>
        )
    }
}